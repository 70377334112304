import CONSTANTS from './constants';
import { getCurrentScrollPosition } from './domHelpers';
export default class CustomScrollSpy {
  constructor(navId, contentId, offset = 100) {
    this.navId = navId;
    this.contentId = contentId;
    this.offset = offset;
    this.nav = null;
    this.content = null;
    this.headings = [];
    this.tocLinks = {};

    this.waitForElements().then(() => this.init());
  }

  waitForElements() {
    return new Promise((resolve) => {
      const checkElements = () => {
        this.nav = document.getElementById(this.navId);
        this.content = document.getElementById(this.contentId);

        if (this.nav && this.content && this.nav.children.length > 0) {
          resolve();
        } else {
          requestAnimationFrame(checkElements);
        }
      };

      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', checkElements);
      } else {
        checkElements();
      }
    });
  }

  init() {
    if (!this.nav || !this.content) {
      console.error('CustomScrollSpy: Required elements not found');
      return;
    }

    this.findHeadings();
    this.createTocLinksMap();
    this.bindScrollEvent();
  }

  findHeadings() {
    const allHeadings = Array.from(this.content.querySelectorAll('h1, h2, h3, h4, h5, h6')).filter(
      (heading) => !heading.hasAttribute(CONSTANTS.TOC_SKIP_ATTRIBUTE)
    );
    this.headings = allHeadings.sort((a, b) => {
      return a.getBoundingClientRect().top - b.getBoundingClientRect().top;
    });
    this.headingsMap = new Map(this.headings.map((h, index) => [h.id, { heading: h, index }]));
  }

  createTocLinksMap() {
    this.tocLinks = {};
    const links = this.nav.querySelectorAll('.nav-link');
    links.forEach((link) => {
      const targetId = link.getAttribute('href').substring(1);
      const positionId = link.getAttribute('data-positional-element-id');
      this.tocLinks[targetId] = { link, positionId: parseInt(positionId, 10) };
    });
  }

  recursivelyMapLinks(element) {
    const foundLinks = element.getElementsByClassName('nav-link');
    Array.from(foundLinks).forEach((link) => {
      const targetId = link.getAttribute('href').substring(1);
      this.tocLinks[targetId] = link;
    });

    const foundUls = element.getElementsByTagName('ul');
    Array.from(foundUls).forEach((ul) => {
      this.recursivelyMapLinks(ul);
    });
  }

  bindScrollEvent() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll() {
    const scrollPosition = getCurrentScrollPosition() + this.offset;
    let currentHeadingIndex = this.headings.findIndex((heading) => heading.offsetTop > scrollPosition) - 1;
    if (currentHeadingIndex === -2) currentHeadingIndex = this.headings.length - 1;

    this.updateActiveLinks(currentHeadingIndex);
  }

  updateActiveLinks(currentHeadingIndex) {
    Object.values(this.tocLinks).forEach(({ link }) => link.classList.remove('active'));

    if (currentHeadingIndex >= 0) {
      const activeHeadings = new Set();
      let index = currentHeadingIndex;

      while (index >= 0) {
        const heading = this.headings[index];
        activeHeadings.add(heading.id);

        // Find the next parent heading
        const currentLevel = parseInt(heading.tagName.charAt(1));
        index--;
        while (index >= 0) {
          const parentLevel = parseInt(this.headings[index].tagName.charAt(1));
          if (parentLevel < currentLevel) break;
          index--;
        }
      }

      activeHeadings.forEach((headingId) => {
        const tocLink = this.tocLinks[headingId];
        if (tocLink) {
          tocLink.link.classList.add('active');
        }
      });
    }
  }
}
