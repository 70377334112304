export function convertRemToPixels(rem) {
  return parseFloat(getComputedStyle(document.documentElement).fontSize) * rem;
}

export function createElementWithAttributes(tag, attributes) {
  const element = document.createElement(tag);
  Object.entries(attributes).forEach(([key, value]) => element.setAttribute(key, value));
  return element;
}

export function getCurrentScrollPosition() {
  return window.pageYOffSet || document.documentElement.scrollTop || 0;
}
