export default Object.freeze({
  BLOG_POST_CONTENT_ID: 'post-content',
  BLOG_SUMMARY_SELECTOR: '.summary',
  MAIN_NAVBAR_ID: 'main-navbar',
  START_ANSWER_BUTTON_ID: 'blog_floating_cta',
  ARTICLES_LIST_HEADER_TEXT: 'How to Answer a Summons for debt collection in all 50 states',

  // Table of Contents constants
  TOC_DATA_TOGGLE_ATTRIBUTE: 'data-toggle="toc"',
  TOC_DESKTOP_COLUMN_ID: 'toc-desktop-column',
  TOC_DESKTOP_CONTAINER_ID: 'toc-desktop-container',
  TOC_DROPDOWN_BUTTON_ID: 'toc-dropdown-button',
  TOC_DROPDOWN_MENU_ID: 'toc-dropdown-menu',
  TOC_HEADER_ID: 'toc-header',
  TOC_ID: 'toc',
  TOC_MOBILE_CONTAINER_ID: 'toc-mobile-container',
  TOC_MOBILE_STICKY_WRAPPER_ID: 'toc-mobile-sticky-wrapper',
  TOC_MOBILE_TEXT: 'Contents',
  TOC_SKIP_ATTRIBUTE: 'data-toc-skip',
  TOC_TEXT_ATTRIBUTE: 'data-toc-text',

  COMMENTO: {
    // IDs
    DIV_ID: 'commento',
    HEADER_ID: 'commento-header',
    WRAPPER_ID: 'commento-wrapper',

    // URLs
    SCRIPT_SOURCE: 'https://cdn.commento.io/js/commento.js',
    CSS_SOURCE: '/blog_commento.css',

    // Selectors
    ROOT_CLASS: '.commento-root',
    CANCEL_BUTTON_CLASS: 'commento-option-cancel',
    REPLY_BUTTON_CLASS: 'commento-option-reply',
    REPLY_BUTTONS_SELECTOR:
      '.commento-option-button.commento-option-reply, .commento-option-button.commento-option-cancel',
    TEXT_AREA_SELECTOR: '.commento-root textarea',
    COMMENT_CARD_CLASS: '.commento-card',
    COMMENT_TEXT_PREFIX: 'commento-comment-text-',
    SUBMIT_BUTTON_CLASS: '.commento-submit-button',
    ANONYMOUS_CHECKBOX_CLASS: '.commento-anonymous-checkbox-container',
    CONTROLS_CONTAINER_CLASS: 'commento-controls-container',
    SUBMIT_WRAPPER_CLASS: 'submit-button-wrapper',
    CHECKBOX_WRAPPER_CLASS: 'checkbox-wrapper',

    // Text content
    TEXT_AREA_PLACEHOLDER_TEXT: 'Add a comment',
    NEW_TEXT_AREA_PLACEHOLDER_TEXT: 'Add a comment...',
    REPLY_BUTTON_TEXT: 'Reply',
    HEADER_TEXT: 'Comments',

    // Limits
    MAX_CHARACTERS: 10000,
  },
});
