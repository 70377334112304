$(document).ready(function () {
    const isSignPage = window.location.pathname.includes('/users/sign_in');
    
    if (!document.querySelector('input[type="password"]') || isSignPage) return;
    
    const requirements = {
        hasUpperCase: /[A-Z]/,
        hasLowerCase: /[a-z]/,
        hasDigit: /\d/,
        hasSpecial: /[!@#$%^&*(),.?":{}|<>\-]/,
        minLength: 9
    };

    const createRequirementsElement = (input) => {
        const feedbackDiv = $('<div>', {
            id: 'password-requirements',
            class: 'mt-2',
            css: { fontSize: '0.875rem' }
        });

        const requirements = [
            { id: 'length', text: 'At least 9 characters' },
            { id: 'uppercase', text: 'One uppercase letter' },
            { id: 'lowercase', text: 'One lowercase letter' },
            { id: 'digit', text: 'One number' },
            { id: 'special', text: 'One special character' }
        ];

        requirements.forEach(req => {
            $('<div>', {
                id: `pwd-req-${req.id}`,
                class: 'text-muted',
                text: `○ ${req.text}`
            }).appendTo(feedbackDiv);
        });

        $(input).after(feedbackDiv);
        return feedbackDiv;
    };

    const createConfirmationElement = (input) => {
        const feedbackDiv = $('<div>', {
            id: 'password-confirmation',
            class: 'mt-2',
            css: { fontSize: '0.875rem' }
        });

        $('<div>', {
            id: 'pwd-confirmation-match',
            class: 'text-muted',
            text: '○ Passwords match'
        }).appendTo(feedbackDiv);

        $(input).after(feedbackDiv);
        return feedbackDiv;
    };

    const updateRequirements = (input, feedbackDiv) => {
        const value = input.value;

        const checks = {
            'length': value.length >= requirements.minLength,
            'uppercase': requirements.hasUpperCase.test(value),
            'lowercase': requirements.hasLowerCase.test(value),
            'digit': requirements.hasDigit.test(value),
            'special': requirements.hasSpecial.test(value)
        };

        Object.entries(checks).forEach(([key, passes]) => {
            const element = feedbackDiv.find(`#pwd-req-${key}`);
            element
                .removeClass('text-muted text-success')
                .addClass(passes ? 'text-success' : 'text-muted')
                .text(`${passes ? '✓' : '○'} ${element.text().slice(2)}`);
        });

        const isValid = Object.values(checks).every(v => v);
        $(input)
            .removeClass('is-invalid is-valid')
            .addClass(value.length > 0 ? (isValid ? 'is-valid' : 'is-invalid') : '');
    };

    const updateConfirmation = (confirmInput, originalInput, feedbackDiv) => {
        const confirmValue = $(confirmInput).val();
        const originalValue = $(originalInput).val();
        const matches = confirmValue === originalValue;

        const element = feedbackDiv.find('#pwd-confirmation-match');
        element
            .removeClass('text-muted text-success')
            .addClass(matches && confirmValue.length > 0 ? 'text-success' : 'text-muted')
            .text(`${matches && confirmValue.length > 0 ? '✓' : '○'} Passwords match`);

        $(confirmInput)
            .removeClass('is-invalid is-valid')
            .addClass(confirmValue.length > 0 ? (matches ? 'is-valid' : 'is-invalid') : '');
    };

    const newPasswordInput = $('input#user_password');
    const confirmPasswordInput = $('input#user_password_confirmation');

    if (newPasswordInput.length > 0) {
        const feedbackDiv = createRequirementsElement(newPasswordInput);

        newPasswordInput.on('input', function(event) {
            updateRequirements(this, feedbackDiv);
        });

        if (newPasswordInput.value) {
            updateRequirements(newPasswordInput, feedbackDiv);
        }
    }

    if (confirmPasswordInput.length > 0) {
        const feedbackDiv = createConfirmationElement(confirmPasswordInput);

        confirmPasswordInput.on('input', function() {
            updateConfirmation(this, newPasswordInput[0], feedbackDiv);
        });

        if (confirmPasswordInput.value) {
            updateConfirmation(confirmPasswordInput, newPasswordInput, feedbackDiv);
        }
    }
});