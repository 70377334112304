import CONSTANTS from './constants';
import { createElementWithAttributes } from './domHelpers';

const COMMENTO = {
  init() {
    if (!this.validateInitialization()) return;
    this.injectCommentoElements();
    this.initializeObservers();
  },

  validateInitialization() {
    const postContent = document.getElementById(CONSTANTS.BLOG_POST_CONTENT_ID);
    if (!postContent) return false;

    const mobileTocWrapper = document.getElementById(CONSTANTS.TOC_MOBILE_STICKY_WRAPPER_ID);
    if (mobileTocWrapper) {
      throw new Error('Commento must be initialized before TOC to ensure proper header inclusion');
    }

    return true;
  },

  // DOM manipulation methods
  injectCommentoElements() {
    const postContent = document.getElementById(CONSTANTS.BLOG_POST_CONTENT_ID);
    const commentoWrapper = this.createCommentoStructure();
    this.insertCommentoIntoDOM(postContent, commentoWrapper);
  },

  createCommentoStructure() {
    const wrapper = createElementWithAttributes('div', {
      id: CONSTANTS.COMMENTO.WRAPPER_ID,
    });

    const header = createElementWithAttributes('h2', {
      id: CONSTANTS.COMMENTO.HEADER_ID,
    });
    header.textContent = CONSTANTS.COMMENTO.HEADER_TEXT;

    const commentoDiv = createElementWithAttributes('div', {
      id: CONSTANTS.COMMENTO.DIV_ID,
    });

    const script = createElementWithAttributes('script', {
      defer: '',
      src: CONSTANTS.COMMENTO.SCRIPT_SOURCE,
      'data-css-override': CONSTANTS.COMMENTO.CSS_SOURCE,
    });

    wrapper.appendChild(header);
    wrapper.appendChild(commentoDiv);
    wrapper.appendChild(script);

    return wrapper;
  },

  insertCommentoIntoDOM(postContent, commentoWrapper) {
    const articlesList = Array.from(postContent.getElementsByTagName('h2')).find(
      (h2) => h2.textContent.trim() === CONSTANTS.ARTICLES_LIST_HEADER_TEXT
    );

    if (articlesList) {
      articlesList.parentNode.insertBefore(commentoWrapper, articlesList);
    } else {
      postContent.appendChild(commentoWrapper);
    }
  },

  // Observer initialization and management
  initializeObservers() {
    const rootObserver = new MutationObserver((mutations) => {
      const commentoRoot = document.querySelector(CONSTANTS.COMMENTO.ROOT_CLASS);
      if (commentoRoot) {
        rootObserver.disconnect();
        this.initReplyButtonObserver(commentoRoot);
        this.initTextareaObserver(commentoRoot);
      }
    });

    rootObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  },

  initReplyButtonObserver(commentoRoot) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => this.handleReplyButtons());
    });

    observer.observe(commentoRoot, {
      childList: true,
      subtree: true,
    });
  },

  handleReplyButtons() {
    const replyButtons = document.querySelectorAll(CONSTANTS.COMMENTO.REPLY_BUTTONS_SELECTOR);

    replyButtons.forEach((button) => {
      this.styleReplyButton(button);
      this.repositionReplyButton(button);
    });
  },

  styleReplyButton(button) {
    if (button.classList.contains(CONSTANTS.COMMENTO.CANCEL_BUTTON_CLASS)) {
      button.textContent = '';
      button.setAttribute('data-reply-button', 'false');
    } else if (button.classList.contains(CONSTANTS.COMMENTO.REPLY_BUTTON_CLASS)) {
      button.classList.remove(CONSTANTS.COMMENTO.REPLY_BUTTON_CLASS);
      button.textContent = CONSTANTS.COMMENTO.REPLY_BUTTON_TEXT;
      button.setAttribute('data-reply-button', 'true');
    }
  },

  repositionReplyButton(button) {
    const commentCard = button.closest(CONSTANTS.COMMENTO.COMMENT_CARD_CLASS);
    if (!commentCard) return;

    // Check if this is an edit action by looking for a textarea with content
    const textarea = commentCard.querySelector('textarea');
    const isEditAction = textarea && textarea.value.trim().length > 0;
    if (isEditAction) return;

    const commentText = commentCard.querySelector(`[id^="${CONSTANTS.COMMENTO.COMMENT_TEXT_PREFIX}"]`);
    if (!commentText) return;

    button.style.position = 'static';
    const nextSibling = commentText.nextSibling;
    if (nextSibling !== button) {
      commentText.parentNode.insertBefore(button, nextSibling);
    }
  },

  initTextareaObserver(commentoRoot) {
    const observer = new MutationObserver(() => {
      const textareas = document.querySelectorAll(CONSTANTS.COMMENTO.TEXT_AREA_SELECTOR);
      textareas.forEach((textarea) => {
        if (textarea.getAttribute('placeholder') === CONSTANTS.COMMENTO.TEXT_AREA_PLACEHOLDER_TEXT) {
          this.setupTextarea(textarea);
        }
      });
    });

    observer.observe(commentoRoot, {
      childList: true,
      subtree: true,
    });
  },

  setupTextarea(textarea) {
    this.styleTextarea(textarea);
    this.setupControlsContainer(textarea);
    this.initializeTextareaEvents(textarea);
  },

  styleTextarea(textarea) {
    Object.assign(textarea.style, {
      height: 'auto',
      paddingTop: '0',
      paddingBottom: '0',
      lineHeight: '1',
      fontWeight: '300',
    });
    textarea.setAttribute('placeholder', CONSTANTS.COMMENTO.NEW_TEXT_AREA_PLACEHOLDER_TEXT);
    textarea.setAttribute('maxlength', CONSTANTS.COMMENTO.MAX_CHARACTERS);
  },

  setupControlsContainer(textarea) {
    const textAreaParent = textarea.parentElement.parentElement;
    const submitButton = textAreaParent?.querySelector(CONSTANTS.COMMENTO.SUBMIT_BUTTON_CLASS);
    const anonymousCheckbox = textAreaParent?.querySelector(CONSTANTS.COMMENTO.ANONYMOUS_CHECKBOX_CLASS);

    if (submitButton && !textAreaParent.querySelector(`.${CONSTANTS.COMMENTO.CONTROLS_CONTAINER_CLASS}`)) {
      const container = this.createControlsContainer(submitButton, anonymousCheckbox);
      textarea.parentElement.after(container);
      this.initializeControlsVisibility(textarea, submitButton, anonymousCheckbox);
    }
  },

  createControlsContainer(submitButton, anonymousCheckbox) {
    const container = document.createElement('div');
    container.className = CONSTANTS.COMMENTO.CONTROLS_CONTAINER_CLASS;

    const submitWrapper = document.createElement('div');
    submitWrapper.className = CONSTANTS.COMMENTO.SUBMIT_WRAPPER_CLASS;
    submitButton.parentNode.insertBefore(submitWrapper, submitButton);
    submitWrapper.appendChild(submitButton);

    if (anonymousCheckbox) {
      const checkboxWrapper = document.createElement('div');
      checkboxWrapper.className = CONSTANTS.COMMENTO.CHECKBOX_WRAPPER_CLASS;
      anonymousCheckbox.parentNode.insertBefore(checkboxWrapper, anonymousCheckbox);
      checkboxWrapper.appendChild(anonymousCheckbox);
      container.appendChild(checkboxWrapper);
    }

    container.appendChild(submitWrapper);

    return container;
  },

  initializeControlsVisibility(textarea, submitButton, anonymousCheckbox) {
    const hasText = textarea.value.trim().length > 0;
    this.updateControlsVisibility(submitButton, anonymousCheckbox, hasText);
  },

  initializeTextareaEvents(textarea) {
    textarea.addEventListener('input', () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;

      const hasText = textarea.value.trim().length > 0;
      const textAreaParent = textarea.parentElement.parentElement;
      const submitButton = textAreaParent?.querySelector(CONSTANTS.COMMENTO.SUBMIT_BUTTON_CLASS);
      const anonymousCheckbox = textAreaParent?.querySelector(CONSTANTS.COMMENTO.ANONYMOUS_CHECKBOX_CLASS);

      this.updateControlsVisibility(submitButton, anonymousCheckbox, hasText);
    });
  },

  updateControlsVisibility(submitButton, anonymousCheckbox, hasText) {
    const visibilityStyles = {
      height: hasText ? '40px' : '0',
      marginTop: hasText ? '4px' : '0',
    };

    if (submitButton) {
      Object.assign(submitButton.parentElement.style, {
        ...visibilityStyles,
        transition: 'height 0.2s ease-out, margin-top 0.2s ease-out',
      });
      submitButton.style.opacity = hasText ? '1' : '0';
      submitButton.style.transition = 'opacity 0.2s ease-out';
    }

    if (anonymousCheckbox) {
      Object.assign(anonymousCheckbox.parentElement.style, {
        ...visibilityStyles,
        transition: 'height 0.2s ease-out, margin-top 0.2s ease-out',
      });
      anonymousCheckbox.style.opacity = hasText ? '1' : '0';
      anonymousCheckbox.style.transition = 'opacity 0.2s ease-out';
    }
  },
};

$(document).ready(COMMENTO.init.bind(COMMENTO));
