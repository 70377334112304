let polling_counter = 0;
document.addEventListener('download_link_polling_event', (event) => {
    if (document.getElementById('download_link_polling_status').value == 'true') {
        stopPolling(event)
        document.getElementsByClassName('btn')[0].disabled = false
    } else if (++polling_counter > 20) {
        stopPolling(event)
        event.container.innerHTML = '<div id="error-message">There was an error generating your document. Please refresh the page to try again.</div>'
    }
})

document.addEventListener('await_error_event', (event) => {
    stopPolling(event)
})

function stopPolling(event) {
    event.container.dispatchEvent(new Event('async-stop'))
}
