// https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
$(document).ready(function () {
  if (!document.querySelector('input[id*="phone_number"]')) return;
  const phoneNumberInputs = $('input[id*="phone_number"]');

  const isNumericInput = (event) => /^\d$/.test(String.fromCharCode(event.which));

  const isModifierKey = (event) => {
    const key = event.key;
    const ctrl = event.ctrlKey || event.metaKey;

    // allowed keys
    const functionKeys = ['Backspace', 'Tab', 'Enter', 'Home', 'End', 'Delete'];
    const arrowKeys = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];
    const ctrlCombos = ['a', 'c', 'v', 'x', 'z'];

    return (
      event.shiftKey ||
      functionKeys.includes(key) ||
      arrowKeys.includes(key) ||
      (ctrl && ctrlCombos.includes(key.toLowerCase())) // user wants to paste phone number
    );
  };

  const enforceFormat = (event) => {
    const input = event.target.value.replace(/\D/g, '');
    const isAtMaxLength = input.length >= 10;

    // input must be of a valid number format or a modifier key, and not longer than ten digits
    if ((!isNumericInput(event) && !isModifierKey(event)) || (isNumericInput(event) && isAtMaxLength)) {
      event.preventDefault();
    }
  };

  const formatToPhone = (event) => {
    if (isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, '').slice(0, 10);
    const match = input.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return '';

    const [, areaCode, middle, last] = match;

    let formatted = '';
    if (last) formatted = `(${areaCode}) ${middle}-${last}`;
    else if (middle) formatted = `(${areaCode}) ${middle.length == 3 ? middle + '-' : middle}`;
    else if (areaCode) formatted = `(${areaCode.length == 3 ? areaCode + ') ' : areaCode}`;
    event.target.value = formatted;
  };

  phoneNumberInputs.on('keydown', enforceFormat);
  phoneNumberInputs.on('keyup', formatToPhone);
});
