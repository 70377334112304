// from https://guides.rubyonrails.org/v5.2/active_storage_overview.html#example

addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)

    const soloSettle = window.location.pathname.includes("solosettles")
    const emailInstructions = soloSettle ?
        "<a href=\"mailto:support@solosuit.com?subject=SoloSettle Settlement Documents\">send your files to support@solosuit.com</a>." :
        "send your files to support@solosuit.com and then click the following button to skip this step: <button name=\"walk_through_step_response[button_clicked]\" type=\"submit\" value=\"negative\">Skip</button>"
    const chooseFilesButton = document.querySelector("input[type=file][data-direct-upload-url]")
    chooseFilesButton.insertAdjacentHTML("afterend",
        `<br /><span class='direct-upload--error-explanation'>There was an error uploading a file. Refresh the page and try again.<br />If you experience repeated errors, ${emailInstructions}</span>`)
    chooseFilesButton.remove()
})

addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
})
