import $ from "jquery";

$(document).ready(function() {
    if (!document.querySelector("#card-element")) return;

    const stripe = Stripe('pk_live_51EKqBsCvO67G2przyFOcLxrj7qhOxK7KIpiLSuLXEpxyZYPpO7qNDgvvAEVOUkIjWyv2fsd89ekF27RKwkatfDrh00gEVrDGbi', {
        apiVersion: '2023-10-16',
    });
    const elements = stripe.elements();
    var inputComplete = false;
    const cardErrorSection = document.getElementById('card-errors');
    const card = elements.create("card", {
        style: {
            base: {
                //these values are taken from the defaults we are using in bootstrap's "forms" style for .form-control
                color: '#464a4c',
                fontSize: '16px'
            }
        }
    });
    card.mount("#card-element");
    card.on('change', function(event) {
        if (event.error) {
            cardErrorSection.textContent = event.error.message;
        } else {
            cardErrorSection.textContent = '';
        }
        inputComplete = event.complete;
    });

    const form = document.getElementById('payment-form');
    const submitButton = form.querySelector("input[type=submit], button[type=submit]");
    const originalButtonText = submitButton.textContent;
    const buttonDisableText = submitButton.dataset.disableWith;
    delete submitButton.dataset.disableWith;

    function handleSubmit(event) {
        if (!inputComplete) {
            event.preventDefault();
            if (!cardErrorSection.textContent || cardErrorSection.textContent.length === 0) {
                cardErrorSection.textContent = "Your card details are incomplete";
            }
            return;
        }

        event.preventDefault();
        submitButton.textContent = buttonDisableText;
        submitButton.disabled = true;
        stripe.confirmCardPayment(form.dataset.secret, {
            payment_method: {
                card: card
            }
        }).then(function(result) {
            submitButton.disabled = false;
            if (result.error) {
                cardErrorSection.textContent = result.error.message;
                submitButton.textContent = originalButtonText;
            } else if (result.paymentIntent.status === 'succeeded') {
                const paymentIntentInput = form.querySelector("input[type=hidden][name*=payment_intent_id]");
                paymentIntentInput.value = result.paymentIntent.id;

                form.removeEventListener('submit', handleSubmit);
                submitButton.focus();
                submitButton.click();
            }
        });
    }
    form.addEventListener('submit', handleSubmit);

    const targetNode = document.getElementById('card-element');
    const observer = new MutationObserver(function(mutationsList, observer) {
        const labelClassList = form.querySelector('label').classList;
        const highlightClass = 'highlight-label';
        for (const mutation of mutationsList) {
            if (mutation.target.classList.contains('StripeElement--focus')) {
                labelClassList.add(highlightClass);
            } else {
                labelClassList.remove(highlightClass);
            }
            console.log('The ' + mutation.attributeName + ' attribute was modified.');
        }
    });
    observer.observe(document.getElementById('card-element'), { attributes: true });
});
$(document).ready(function() {
    if (!document.querySelector("#payment-element")){
        return;
    }
    const form = document.getElementById('payment-form');
    const submitButton = form.querySelector("input[type=submit], button[type=submit]");
    const originalButtonText = submitButton.textContent;
    const buttonDisableText = submitButton.dataset.disableWith;
    const stripe = Stripe('pk_live_51LXZcYB2PeTgiGWPnB8PA4kRwRoKwYpLQTB3Ur2AXJgOsWsE1fNmeKL5TtnJkC5G7J4MRNMe9JLKMVC6DCfAj3dh00P2iS75NT', {
        apiVersion: '2023-10-16',
    });
    const options = {
      clientSecret: form.dataset.secret
    };
    const elements = stripe.elements(options);
    const paymentErrorSection = document.getElementById('payment-errors');
    const paymentElement = elements.create("payment", {
        fields: {
            billingDetails: {
                email: 'never'
            }
        }
    });
    paymentElement.mount("#payment-element");
    paymentElement.on('change', function(event) {
        if (event.error) {
            paymentErrorSection.textContent = event.error.message;
        } else {
            paymentErrorSection.textContent = '';
        }
    });

    function handleSubmit(event) {
        event.preventDefault();

        submitButton.disabled = true;
        submitButton.textContent = buttonDisableText;
        stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: form.dataset.returnUrl, //where the customer should be redirected after the PaymentIntent is confirmed
                payment_method_data: {
                    billing_details: {
                        email: form.dataset.customerEmail,
                    }
                }
            }
        }).then(function(result) {
            submitButton.disabled = false;
            if (result.error) {
                paymentErrorSection.textContent = result.error.message;
                submitButton.textContent = originalButtonText;
            }
        });
    }
    form.addEventListener('submit', handleSubmit);
});
