import CONSTANTS from './constants';
import CustomScrollSpy from './customScrollSpy';
import { createElementWithAttributes, convertRemToPixels, getCurrentScrollPosition } from './domHelpers';

const TOC = {
  init() {
    const toc = document.querySelector(`nav[${CONSTANTS.TOC_DATA_TOGGLE_ATTRIBUTE}]`);
    const postContent = document.getElementById(CONSTANTS.BLOG_POST_CONTENT_ID);
    if (!toc || !postContent) return;

    this.sanitizePostContent();
    this.setTocMaxHeight();
    this.injectMobileToc();
    this.determineTocPlacement();
    this.initializeMobileToc();
    this.initializeScrollHandling();
    this.initializeCustomScrollSpy();

    window.addEventListener('resize', () => {
      this.determineTocPlacement();
      this.setTocMaxHeight(toc);
      this.initializeMobileToc();
    });
  },

  initializeCustomScrollSpy() {
    const offset = this.isMobile() ? window.innerHeight * 0.4 : 100;
    new CustomScrollSpy(CONSTANTS.TOC_ID, CONSTANTS.BLOG_POST_CONTENT_ID, offset);
  },

  isMobile() {
    const tocDesktopColumn = document.getElementById(CONSTANTS.TOC_DESKTOP_COLUMN_ID);
    return window.getComputedStyle(tocDesktopColumn).display === 'none';
  },

  determineTocPlacement() {
    const toc = document.getElementById(CONSTANTS.TOC_ID);

    if (this.isMobile()) {
      const dropdownMenu = document.getElementById(CONSTANTS.TOC_DROPDOWN_MENU_ID);
      dropdownMenu.appendChild(toc);
    } else {
      const tocDesktopContainer = document.getElementById(CONSTANTS.TOC_DESKTOP_CONTAINER_ID);
      tocDesktopContainer.appendChild(toc);
    }
  },

  sanitizePostContent() {
    const postContent = document.getElementById(CONSTANTS.BLOG_POST_CONTENT_ID);
    const headers = postContent.querySelectorAll('h1, h2, h3, h4, h5, h6');
    const sanitizeHeaderText = (text) =>
      text
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    headers.forEach((header) => {
      if (header.hasAttribute(CONSTANTS.TOC_SKIP_ATTRIBUTE)) return;

      const tocText = header.textContent.trim();
      const tocId = header.getAttribute(CONSTANTS.TOC_TEXT_ATTRIBUTE) || sanitizeHeaderText(tocText);

      if (!header.id) {
        header.id = tocId;
      }
    });
  },

  setTocMaxHeight() {
    const toc = document.querySelector(`nav[${CONSTANTS.TOC_DATA_TOGGLE_ATTRIBUTE}]`);
    const navbar = document.getElementById(CONSTANTS.MAIN_NAVBAR_ID);
    const startAnswerButton = document.getElementById(CONSTANTS.START_ANSWER_BUTTON_ID);
    const tocHeader = document.getElementById(CONSTANTS.TOC_HEADER_ID);

    if (navbar && toc && startAnswerButton && tocHeader) {
      const navbarHeight = window.scrollY < navbar.offsetHeight ? navbar.offsetHeight : convertRemToPixels(2);
      const buttonHeight = startAnswerButton.offsetHeight;
      const tocHeaderHeight = tocHeader.offsetHeight;
      const windowHeight = window.innerHeight;
      const padding = convertRemToPixels(3);

      const maxHeight = windowHeight - navbarHeight - buttonHeight - tocHeaderHeight - padding;
      toc.style.maxHeight = `${maxHeight}px`;
    }
  },

  injectMobileToc() {
    const postContent = document.getElementById(CONSTANTS.BLOG_POST_CONTENT_ID);
    const summaryDiv = postContent.querySelector(CONSTANTS.BLOG_SUMMARY_SELECTOR);
    if (!summaryDiv) return;

    const stickyWrapper = createElementWithAttributes('div', {
      id: CONSTANTS.TOC_MOBILE_STICKY_WRAPPER_ID,
      class: 'd-lg-none',
    });

    const mobileTocContainer = createElementWithAttributes('div', {
      id: CONSTANTS.TOC_MOBILE_CONTAINER_ID,
    });

    const dropdownButton = createElementWithAttributes('button', {
      class: 'btn btn-primary dropdown-toggle w-100',
      type: 'button',
      id: CONSTANTS.TOC_DROPDOWN_BUTTON_ID,
      'data-bs-toggle': 'dropdown',
      'data-bs-auto-close': 'true',
      'aria-expanded': 'false',
    });
    dropdownButton.textContent = CONSTANTS.TOC_MOBILE_TEXT;
    this.initialButtonBorderRadius = window.getComputedStyle(dropdownButton).getPropertyValue('border-radius');

    const dropdownMenu = createElementWithAttributes('div', {
      id: CONSTANTS.TOC_DROPDOWN_MENU_ID,
      class: 'dropdown-menu w-100',
      'aria-labelledby': CONSTANTS.TOC_DROPDOWN_BUTTON_ID,
    });

    mobileTocContainer.appendChild(dropdownButton);
    mobileTocContainer.appendChild(dropdownMenu);
    stickyWrapper.appendChild(mobileTocContainer);
    summaryDiv.parentNode.insertBefore(stickyWrapper, summaryDiv.nextSibling);
  },

  initializeMobileToc() {
    const postContent = document.getElementById(CONSTANTS.BLOG_POST_CONTENT_ID);
    const mobileTocWrapper = document.getElementById(CONSTANTS.TOC_MOBILE_STICKY_WRAPPER_ID);
    const tocMobileContainer = document.getElementById(CONSTANTS.TOC_MOBILE_CONTAINER_ID);
    const dropdownButton = document.getElementById(CONSTANTS.TOC_DROPDOWN_BUTTON_ID);
    const totalMobilePadding =
      tocMobileContainer.offsetHeight + parseFloat(window.getComputedStyle(tocMobileContainer).marginBottom);
    this.updateTriggerPoint();
    const initialTriggerPoint = this.triggerPoint;

    window.addEventListener('scroll', () => {
      const scrollPosition = getCurrentScrollPosition();
      const isFixed = scrollPosition >= initialTriggerPoint;
      mobileTocWrapper.classList.toggle('fixed', isFixed);
      dropdownButton.style.borderRadius = isFixed ? '0' : null;
      postContent.style.paddingTop = isFixed ? `${totalMobilePadding}px` : '0';
    });
  },

  updateTriggerPoint() {
    const summaryDiv = document.querySelector(CONSTANTS.BLOG_SUMMARY_SELECTOR);
    if (summaryDiv) {
      const rect = summaryDiv.getBoundingClientRect();
      const styles = window.getComputedStyle(summaryDiv);
      const marginBottom = parseFloat(styles.marginBottom);
      this.triggerPoint = rect.bottom + marginBottom + getCurrentScrollPosition();
    }
  },

  initializeScrollHandling() {
    const tocNav = document.querySelector(`nav[${CONSTANTS.TOC_DATA_TOGGLE_ATTRIBUTE}]`);
    tocNav.addEventListener('click', (event) => {
      if (event.target.tagName === 'A') {
        event.preventDefault();
        const $target = $(event.target.hash);
        const tocHeight = $(`#${CONSTANTS.TOC_MOBILE_STICKY_WRAPPER_ID}`).outerHeight() || 0;

        $(window).scrollTop($target.offset().top - tocHeight);
      }
    });
  },
};

$(document).ready(TOC.init.bind(TOC));
